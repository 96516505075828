import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVehicles } from '../server/api/vehicle';
import ProductCard2 from '../segment/Products/productCard2';
// import Search from './Search';

function VehicleSearch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allVehicleList } = useSelector((state) => ({
    allVehicleList: state.vehicle.allVehicleList,
  }));

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const credentials = {
      page: 1,
      limit: 10,
      search: searchText,
    };
    dispatch(getAllVehicles(credentials));
  }, [dispatch, searchText]);

  return (
    <>
      <Stack position={'absolute'} top={0} width={'100%'}>
        <Box position={'relative'}>
          {/* <Search isMobile isSingle /> */}
          <ArrowBackIcon
            fontSize={'medium'}
            onClick={() => navigate(-1)}
            sx={{ position: 'absolute', top: 16, left: 5, zIndex: 1, color: 'white' }}
          />
          <TextField
            fullWidth
            name="search"
            placeholder="Search here"
            id="fullWidth"
            type="text"
            className="search-field"
            autoFocus="autofocus"
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Box>
        <Box minHeight={'92vh'} sx={{ backgroundColor: '#121212' }} pt={3}>
          {searchText && allVehicleList?.vehicles?.map((vehicle, key) => <ProductCard2 key={key} vehicle={vehicle} />)}
        </Box>
      </Stack>
    </>
  );
}

export default VehicleSearch;
