import React, { Fragment } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
// components
import Category from '../segment/@common/Category/category';
import Brands from '../segment/@common/Banner/brands';
import FeaturedCars from '../segment/@common/Banner/featuredCars';
// import { useDevice } from '../hooks/useResponsive';
import Banner from '../segment/@common/Banner/banner';
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import Feature from '../segment/@common/Feature/feature';
import Youtube from '../segment/@common/Youtube/youtube';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function HomePage() {
  // const { isMobile } = useDevice();
  return (
    <>
      <MetaHelmet title={'Home'} companyName={'Focus Motors'} />

      <StyledRoot className="bg-color">
        <Container className="landgmainsection" maxWidth="lg" sx={{ pb: 7 }}>
          <Banner />
          <FeaturedCars />
          <Brands />
          <Feature />
          <Category />
          <Youtube />
        </Container>
      </StyledRoot>
    </>
  );
}
