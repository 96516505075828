import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// eslint-disable-next-line import/no-unresolved
import { TemporaryDrawer } from 'components/drawer/navDrawer';
// import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
// import DehazeIcon from '@mui/icons-material/Dehaze';

// import MenuItem from '@mui/material/MenuItem';
// eslint-disable-next-line import/no-unresolved
import { useDevice } from 'hooks/useResponsive';
import { Grid, Link, Stack, TextField, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import SellIcon from '@mui/icons-material/Sell';
import AirportShuttleRoundedIcon from '@mui/icons-material/AirportShuttleRounded';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// import SearchSharpIcon from '@mui/icons-material/SearchSharp';
// import { Menu } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVehicles } from '../../server/api/vehicle';
import Logo from '../../components/logo/Logo';

const pages = [
  { item: 'Home', link: '/home' },
  { item: 'Buy', link: '/allproducts' },
  { item: 'Sell', link: '/sell' },
  { item: 'Dream Car', link: '/dream' },
];

function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isMobile } = useDevice();

  const [search, setSearch] = React.useState('');
  const { allVehicleList } = useSelector((state) => ({
    allVehicleList: state.vehicle.allVehicleList?.vehicles,
  }));
  const [show, setShow] = React.useState(false);
  const searchRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShow(false); // Close search if click occurs outside the search box
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  React.useEffect(() => {
    const data = {
      search,
    };
    dispatch(getAllVehicles(data));
  }, [dispatch, search]);

  const handleSearch = (value) => {
    console.log('value =>', value);
    setSearch(value);
  };
  // const [anchorElNav, setAnchorElNav] = React.useState(null);

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#121212',
        boxShadow: 'none',
        p: { xs: 0, sm: 5 },
        px: { xs: 0, sm: 10 },
        pb: { xs: 1 },
        pt: { xs: 2 },
      }}
    >
      <Container maxWidth={isMobile ? 'lg' : ''}>
        <Toolbar disableGutters>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Logo />
            {isMobile && <TemporaryDrawer />}
          </Box>

          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages?.map((page, key) => (
                <MenuItem key={key} onClick={handleCloseNavMenu}>
                  <Link to={page?.link} style={{ textDecoration: 'none', textAlign: 'center' }}>
                    <Typography textAlign="center" sx={{ color: 'black' }}>
                      {page?.item}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          {!isMobile && (
            <Box display={'flex'} flexDirection={'column'} sx={{ backgroundColor: '#121212', ml: 25 }}>
              {show ? (
                <CloseIcon
                  onClick={() => setShow(false)}
                  sx={{ cursor: 'pointer', transform: 'rotate(90deg)', color: '#FFA800' }}
                />
              ) : (
                <SearchIcon
                  onClick={() => setShow(true)}
                  sx={{ cursor: 'pointer', transform: 'rotate(90deg)', color: '#FFA800' }}
                />
              )}
              {show && (
                <Box ref={searchRef} display={'flex'} flexDirection={'column'} py={1} className="after-search">
                  <TextField
                    className="search-bar-web"
                    type="text"
                    value={search}
                    placeholder="Search Here..."
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                  {search && search?.length >= 1 && allVehicleList?.length !== 0 && (
                    <Stack className="search-details">
                      {allVehicleList?.map((item, key) => (
                        <Link
                          href={`/product-single/${item?._id}`}
                          key={key}
                          sx={{ textDecoration: 'none', color: 'white' }}
                        >
                          <Box display={'flex'} p={1}>
                            <img
                              src={item?.images[0]?.url}
                              alt=""
                              height={25}
                              width={50}
                              style={{ objectFit: 'contain' }}
                            />
                            &nbsp;
                            <Typography>{item?.name}</Typography>
                          </Box>
                        </Link>
                      ))}
                    </Stack>
                  )}
                  {allVehicleList?.length === 0 && <Typography className="search-details">{'No Vehicle'}</Typography>}
                </Box>
              )}
            </Box>
          )}

          <Box width={'100%'} sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'end' }}>
            {pages?.map((page, key) => (
              <Link
                key={key}
                // component={Link}
                href={page?.link}
                // onClick={handleCloseNavMenu}
                sx={{ my: 2, color: '#E3E3E3', display: 'flex', alignItems: 'center', mx: 3, textDecoration: 'none' }}
              >
                {page?.item === 'Home' ? (
                  <HomeIcon fontSize="small" className="head-icon-color" />
                ) : page?.item === 'Buy' ? (
                  <AirportShuttleRoundedIcon fontSize="small" className="head-icon-color" />
                ) : page?.item === 'Sell' ? (
                  <SellIcon fontSize="small" className="head-icon-color" />
                ) : (
                  <DriveEtaIcon fontSize="small" className="head-icon-color" />
                )}
                <Typography sx={{ padding: '3px 0px 0px 4px' }} fontWeight={600}>
                  {page?.item}
                </Typography>
              </Link>
            ))}
          </Box>
        </Toolbar>
        {(location?.pathname === '/home' || location?.pathname === '/allproducts') && isMobile && (
          <Link href={'/search'} style={{ textDecoration: 'none' }}>
            <Grid mt={2}>
              <TextField
                placeholder="Search Your fav! 🚗"
                type="text"
                className="custom-field-sell"
                sx={{ border: '0px solid gray', borderRadius: '10px', width: '100%' }}
                InputProps={{ autoComplete: 'off' }}
              />
              <img
                src={`assets/images/Header/search.png`}
                alt=""
                style={{ position: 'absolute', top: 105, right: 30 }}
              />
            </Grid>
          </Link>
        )}
      </Container>
    </AppBar>
  );
}

export default Header;
