import React from 'react';

const DreamCarIcon = () => (
  <svg width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.75 6H31M1 6H2.25M2.09375 8C2.09375 8 5.71484 7.47368 16 7.47368C26.2852 7.47368 29.9062 8 29.9062 8"
      stroke="#FFA800"
      strokeWidth="1.20506"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.3125 7.81111C29.8216 7.27056 27.9981 6.89833 27.2266 5.92556C26.4552 4.95278 25.8236 2.84611 23.8601 2.03667C21.8966 1.22722 18.1437 1 16.0007 1C13.8577 1 10.1075 1.22222 8.14133 2.035C6.17514 2.84778 5.54631 4.95278 4.77484 5.92389C4.00336 6.895 2.17981 7.27056 1.68894 7.81111C1.19806 8.35167 0.852502 11.7689 1.06278 13.4444C1.27306 15.12 1.6655 16.1111 1.6655 16.1111H7.42477C8.36768 16.1111 8.6744 15.8172 10.6031 15.6667C12.7193 15.5 14.7953 15.4444 16.0007 15.4444C17.2062 15.4444 19.3491 15.5 21.464 15.6667C23.3927 15.8183 23.7095 16.1111 24.6423 16.1111H30.3346C30.3346 16.1111 30.7271 15.12 30.9373 13.4444C31.1476 11.7689 30.8007 8.35167 30.3125 7.81111ZM25.6442 16.1111H29.3944V17H25.6442V16.1111ZM2.60707 16.1111H6.3573V17H2.60707V16.1111Z"
      stroke="#FFA800"
      strokeWidth="1.20506"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.7584 9.56625C27.7546 9.52212 27.7313 9.48071 27.693 9.44995C27.6547 9.41919 27.604 9.40125 27.5508 9.39958C26.7599 9.37625 25.9569 9.42291 24.5325 9.77125C23.8058 9.94725 23.1234 10.2307 22.5181 10.6079C22.3654 10.7068 22.4196 10.974 22.6112 11.0024C23.7852 11.1166 24.9663 11.1741 26.1484 11.1746C26.8576 11.1746 27.5896 11.0079 27.7255 10.4835C27.7949 10.1811 27.8059 9.8715 27.7584 9.56625ZM4.24311 9.56625C4.24687 9.52212 4.27014 9.48071 4.30845 9.44995C4.34677 9.41919 4.39743 9.40125 4.45071 9.39958C5.24161 9.37625 6.04456 9.42291 7.46897 9.77125C8.19569 9.94725 8.87811 10.2307 9.48338 10.6079C9.63607 10.7068 9.58182 10.974 9.39029 11.0024C8.21622 11.1166 7.03514 11.1741 5.85303 11.1746C5.14383 11.1746 4.41187 11.0079 4.27592 10.4835C4.20661 10.1811 4.19554 9.8715 4.24311 9.56625Z"
      fill="#FFA800"
    />
  </svg>
);

export default DreamCarIcon;
