import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDevice } from '../../../hooks/useResponsive';
import { getAllCategoriesIncludeVehicle } from '../../../server/api/category';
import VehiclesByCategory from './vehicleList';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function Category() {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  const { categoryList } = useSelector((state) => ({
    categoryList: state.category.categoryIncludeVehicle?.response?.categoryVehicles,
  }));

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getAllCategoriesIncludeVehicle(credentials));
  }, [dispatch]);

  return (
    <div>
      {categoryList?.map((item, key) => (
        <StyledRoot key={key} sx={{ backgroundColor: '#121212' }}>
          <Container className="main-banner" sx={{ pb: 4, px: 2 }}>
            <Typography className="featured-text" pb={1}>
              {item?.category?.name}
            </Typography>
            <Typography color={'white'} fontSize={10}>
              {item?.category?.description}
            </Typography>
            {isMobile ? (
              <Link to={`/category-single/${item?.category?._id}`} style={{ textDecoration: 'none' }}>
                <Box width={'100%'} mt={2}>
                  {item && item?.category?.appBanners && (
                    <img src={item?.category?.appBanners[0]?.url} alt="" width={'100%'} />
                  )}
                </Box>
              </Link>
            ) : (
              <Link to={`/category-single/${item?.category?._id}`} style={{ textDecoration: 'none' }}>
                {item && item?.category?.webBanners && (
                  <img src={item?.category?.webBanners[0]?.url} alt="" width={'100%'} />
                )}
              </Link>
            )}
            <VehiclesByCategory vehicles={item?.vehicles} />
            <Box display={'flex'} justifyContent={'center'} mt={2}>
              <Link to={`/category-single/${item?.category?._id}`} style={{ textDecoration: 'none' }}>
                <Button className="all-brand-btn" sx={{ px: 6 }}>
                  VIEW ALL IN THIS CATEGORY
                </Button>
              </Link>
            </Box>
          </Container>
        </StyledRoot>
      ))}
    </div>
  );
}
