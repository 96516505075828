import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { post } from './http';

export const createFeedback = createAsyncThunk('/review/new', async ({ state, dispatch, navigate }) => {
  try {
    const URL = `/review/new`;
    const response = await post(URL, state);
    if (response) {
      navigate('/home');
      dispatch(activeSnack({ type: 'success', message: 'Review Submitted Successfully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
