import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { get } from './http';

export const getAllCategories = createAsyncThunk('category/user/all', async ({ page, searchText, limit, dispatch }) => {
  try {
    const response = await get(`/category/user/all?page=${page ?? 1}&search=${searchText ?? ''}&limit=${limit ?? 10}`);
    if (response) {
      return {
        response,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllCategoriesIncludeVehicle = createAsyncThunk('category/vehicle/all', async ({ dispatch }) => {
  try {
    const response = await get(`/vehicle/user/category/vehicles/all`);
    if (response) {
      return {
        response,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getVehiclesByCategory = createAsyncThunk(
  `vehicle/byCategory`,
  async ({ catId, page, searchText, limit, dispatch }) => {
    try {
      const URL = `/vehicle/user/category/${catId ?? ''}?page=${page ?? 1}&search=${searchText ?? ''}&limit=${
        limit ?? 10
      }`;
      const response = await get(URL);
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error;
    }
  }
);

export const getCategoryDetail = createAsyncThunk(`category/user`, async ({ categoryId, dispatch }) => {
  try {
    const response = await get(`/category/user/${categoryId}`);
    if (response) {
      return {
        response,
      };
    }

    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllVehiclesByFeatured = createAsyncThunk('/vehicle/feature/all', async () => {
  try {
    const URL = `/vehicle/all?isFeatured=${true}`;
    const response = await get(URL);
    console.log(response);
    // dispatch(activeSnack({ type: 'success', message: 'Enquiry Submitted successfully' }));
    return response;
    // return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    // dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
