import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { get, post } from './http';

export const createAppointment = createAsyncThunk('/appointment/user/new', async ({ state, dispatch, navigate }) => {
  try {
    const URL = `/appointment/user/new`;
    const response = await post(URL, state);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Booked successfully' }));
      navigate('/home');
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllSlot = createAsyncThunk('/appointment/slots', async ({ dispatch }) => {
  try {
    const URL = `/appointment/slots`;

    const response = await get(URL);
    if (response) {
      return {
        response,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getSlotDetail = createAsyncThunk(`/appointment/user/`, async ({ id, dispatch }) => {
  try {
    const URL = `/appointment/user/${id}`;

    const response = await get(URL);
    if (response) {
      return {
        response,
      };
    }

    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
