import React from 'react';
import { Box, Card, Container, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { Link } from 'react-router-dom';
import { useDevice } from '../../hooks/useResponsive';
// eslint-disable-next-line import/no-unresolved

ProductCard2.propTypes = {
  addMargin: PropTypes.any,
  vehicle: PropTypes.any,
};
export default function ProductCard2({ addMargin, vehicle }) {
  const isMobile = useDevice()?.isMobile;

  const handleOwnershipStatus = (status) => {
    switch (status) {
      case 'first_owner':
        return 'First Owner';
      case 'second_owner':
        return 'Second Owner';
      case 'third_owner':
        return 'Third Owner';
      default:
        return '';
    }
  };
  const image = [{ img: '/assets/car.png' }];
  return (
    <Link to={`/product-single/${vehicle?._id}`} style={{ textDecoration: 'none' }}>
      <Container sx={{ padding: '0px 10px 0px 10px' }}>
        <div style={{ marginBottom: addMargin ? '2rem' : 15, position: 'relative' }}>
          <Card
            style={{
              width: '100%',
              boxShadow: 'none',
              backgroundColor: '#202020',
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              display: 'flex',
              height: isMobile ? '130px' : '200px',
              borderRight: '1px solid #FFA80080',
            }}
          >
            <Stack width={'50%'}>
              {vehicle && vehicle?.images && (
                <img
                  src={vehicle?.images[0]?.url ? vehicle?.images[0]?.url : image[0]?.img}
                  alt=""
                  style={{
                    // height: isMobile ? '' : 200,
                    objectFit: isMobile ? 'cover' : 'cover',
                    borderRadius: '15px',
                    aspectRatio: 4 / 3,
                  }}
                />
              )}
              {vehicle?.makeYear && (
                <Grid className="car-year" display={'flex'} alignItems={'center'}>
                  <CalendarMonthIcon style={{ fontSize: '10px', color: '#121212', marginRight: '2px' }} />
                  <Typography fontSize={10} color={'#121212'} fontWeight={800} sx={{ paddingTop: '2px' }}>
                    {vehicle?.makeYear}
                  </Typography>
                </Grid>
              )}
            </Stack>
            <Stack width={'50%'} display={'flex'} justifyContent={'center'}>
              <Box ml={2}>
                <Typography fontWeight={300} fontSize={17} color={'#FFA800'} pt={1}>
                  {vehicle?.name}
                </Typography>
                <Typography fontWeight={800} fontSize={12} color={'white'}>
                  {vehicle?.variant?.name}
                </Typography>
                <Typography fontWeight={200} fontSize={12} color={'white'} display={'flex'} alignItems={'center'}>
                  {vehicle?.fuelType && <LocalGasStationIcon style={{ color: '#FFA800', fontSize: '12px' }} />}
                  <Typography fontSize={12} pl={1}>
                    {vehicle?.fuelType}
                  </Typography>
                </Typography>
                <Typography fontWeight={200} fontSize={12} color={'white'}>
                  {handleOwnershipStatus(vehicle?.ownershipStatus)}
                </Typography>
              </Box>
            </Stack>
          </Card>
          {vehicle?.isSold ? (
            <img
              src={`/assets/images/Product/sold.png`}
              alt=""
              style={{ position: 'absolute', right: '-15px', top: '30px', width: '100px', height: '100px' }}
            />
          ) : vehicle?.isBooked ? (
            <img
              src={`/assets/images/Product/booked.png`}
              alt=""
              style={{ position: 'absolute', top: '10px', left: '120px' }}
            />
          ) : null}
        </div>
      </Container>
    </Link>
  );
}
