import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { get } from './http';

export const getUserLocation = createAsyncThunk('/location/reverse-geocode', async ({ lat, lng, dispatch }) => {
  try {
    const URL = `/location/reverse-geocode/${lat}/${lng}`;
    const response = await get(URL);
    if (response) {
      // navigate('/home');
      dispatch(activeSnack({ type: 'success', message: 'Review Submitted Successfully' }));
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getSearchPlaces = createAsyncThunk('/location/search/', async ({ query, dispatch }) => {
  try {
    const URL = `/location/search/${query}`;
    const response = await get(URL);
    if (response) {
      // navigate('/home');
      // dispatch(activeSnack({ type: 'success', message: 'Review Submitted Successfully' }));
      return response.places;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
