import { Outlet } from 'react-router-dom';

import { Container, Stack } from '@mui/material';
import Footer from './Footer';
import Header from './Header';

export default function SimpleLayout() {
  return (
    <>
      <Container maxWidth="xl" style={{ backgroundColor: 'white', paddingRight: '0px', paddingLeft: '0px' }}>
        <Stack direction={'column'}>
          <Header />
        </Stack>
        <Outlet />

        <Footer />
      </Container>
    </>
  );
}
