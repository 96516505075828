import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import YouTube from 'react-youtube';
import { useDevice } from '../../../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function Youtube() {
  const { isMobile } = useDevice();

  const opts = {
    width: isMobile ? '310px' : '580px',
    height: isMobile ? '300px' : '320px',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <div>
      <StyledRoot sx={{ marginBottom: 0 }}>
        <Container
          className=""
          maxWidth="lg"
          sx={{
            paddingTop: { xs: '0', sm: '45px' },
            // paddingBottom: { xs: '0', sm: '125px' },
            paddingLeft: { xs: '0', sm: '16px' },
            paddingRight: { xs: '0', sm: '16px' },
          }}
        >
          <Grid container direction="row" className="bg-color">
            <Typography textAlign={'center'} fontSize={22} fontWeight={700} color={'#F8971C'} width={'100%'}>
              It's Dream Moment ✨
            </Typography>

            <Grid className="react-player">
              <YouTube
                videoId="RGBhSS6JtGQ"
                // id={'https://youtu.be/HGgWfjQ9ZeQ?si=qFF6ZrHjJhoXGHEZ'}
                opts={opts}
                className="react-player-video "
                iframeClassName={isMobile && 'w-100'}
                // className={string}
                // style={object}
                // title={string}
                // loading={string}
                // onReady={func}
                // onPlay={func}
                // onPause={func}
                // onEnd={func}
                // onError={func}
                // onStateChange={func}
                // onPlaybackRateChange={func}
                // onPlaybackQualityChange={func}
              />
              <div className="prod-div prod-div-utube" />
            </Grid>
          </Grid>
        </Container>
      </StyledRoot>
    </div>
  );
}
