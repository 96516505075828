import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { del, get, post, put } from './http';

export const createEnquiry = createAsyncThunk('/enquiry/user/new', async ({ state, dispatch, resetForm, navigate }) => {
  try {
    const URL = `/enquiry/user/new`;
    const response = await post(URL, state);
    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Enquiry Submitted successfully' }));
      resetForm();
      navigate(`/home`);
      // Scroll to the top of the page
      window.scrollTo(0, 0);
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const updateEnquiryDetails = createAsyncThunk(
  'enquirys/update',
  async ({ state, enquiryId, dispatch, navigate }) => {
    try {
      const URL = `/enquiry/user/${enquiryId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'Enquiry updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllEnquiry = createAsyncThunk(
  'enquirys/list',
  async ({ page, search, dispatch, limit, sortBy, sortDirection }) => {
    try {
      const URL = `/enquiry/admin/all?page=${page && page}&search=${search && search}&limit=${limit && limit}&sortBy=${
        sortBy && sortBy
      }&sortDirection=${sortDirection && sortDirection}`;

      const URL_DROPDOWN = `/enquiry/admin/all`;

      const response = await get(page ? URL : URL_DROPDOWN);
      if (response) {
        return {
          list: response?.enquirys,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getEnquiryDetails = createAsyncThunk('enquirys/single', async ({ enquiryId, dispatch }) => {
  try {
    const response = await get(`/enquiry/admin/${enquiryId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const deleteEnquiry = createAsyncThunk(
  'enquirys/delete',
  async ({ enquiryId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/enquiry/admin/${enquiryId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Enquiry deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
