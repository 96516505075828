import { createSlice } from '@reduxjs/toolkit';
import { getSearchPlaces, getUserLocation } from '../api/location';

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    loading: false,
    placesLoading: false,
    error: {},
    places: [],
    userPlace: '',
  },
  extraReducers: {
    [getUserLocation.pending]: (state) => {
      state.loading = true;
    },
    [getUserLocation.fulfilled]: (state, action) => {
      state.loading = false;
      state.userPlace = action.payload;
      state.error = {};
    },
    [getUserLocation.rejected]: (state, action) => {
      state.userPlace = false;
      state.error = action.error;
    },

    [getSearchPlaces.pending]: (state) => {
      state.placesLoading = true;
    },
    [getSearchPlaces.fulfilled]: (state, action) => {
      state.loading = false;
      state.places = action.payload;
      state.error = {};
    },
    [getSearchPlaces.rejected]: (state, action) => {
      state.placesLoading = false;
      state.error = action.error;
    },
  },
});
export const { clearLocationError } = locationSlice.actions;

export default locationSlice.reducer;
