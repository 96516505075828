import { configureStore } from '@reduxjs/toolkit';
import EnquiryReducer from './enquiry';
import SlotReducer from './slot';
import CommonReducer from './common';
import feedbackReducer from './feedback';
import locationReducer from './location';
import vehicleReducer from './vehicle';
import categoryReducer from './category';
import leadReducer from './lead';

export default configureStore({
  reducer: {
    enquirys: EnquiryReducer,
    slot: SlotReducer,
    common: CommonReducer,
    feedback: feedbackReducer,
    location: locationReducer,
    vehicle: vehicleReducer,
    category: categoryReducer,
    lead: leadReducer,
  },
});
