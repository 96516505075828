import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Button, Card, CardMedia, Container, Stack, Typography } from '@mui/material';
import Slider from 'react-slick'; // Import the Slider component from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import icons from Material-UI Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBrands } from '../../../server/api/vehicle';
import { useDevice } from '../../../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

Brands.propTypes = {
  addMargin: propTypes.any,
};
export default function Brands({ addMargin }) {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  const { brands } = useSelector((state) => ({
    brands: state.vehicle.brands?.response,
  }));

  useEffect(() => {
    const credentials = {
      dispatch,
      page: 1,
      limit: 30,
      searchText: '',
    };
    dispatch(getAllBrands(credentials));
  }, [dispatch]);

  // Custom left arrow component
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow arrow-left-brand"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ArrowBackIcon fontSize="small" /> {/* Use the ChevronLeftIcon from Material-UI Icons */}
      </div>
    );
  };

  // Custom right arrow component
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow arrow-right-brand"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ArrowForwardIcon fontSize="small" /> {/* Use the ChevronRightIcon from Material-UI Icons */}
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 4 : 8,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // Custom left arrow component
    nextArrow: <CustomNextArrow />, // Custom right arrow component
  };

  // const brands = [
  //   { name: 'Suzuki', image: '/assets/images/Home/suzuki.png' },
  //   { name: 'Hyundai', image: '/assets/images/Home/hyundai.png' },
  //   { name: 'Mahindra', image: '/assets/images/Home/mahindra.png' },
  //   { name: 'Toyota', image: '/assets/images/Home/toyota.png' },
  //   { name: 'Tata', image: '/assets/images/Home/tata.png' },
  //   { name: 'Honda', image: '/assets/images/Home/honda.png' },
  //   { name: 'Ford', image: '/assets/images/Home/ford.png' },
  //   { name: 'Renault', image: '/assets/images/Home/renault.png' },
  //   { name: 'Volkswagen', image: '/assets/images/Home/wolks.png' },
  // ];

  return (
    <div style={{ marginBottom: addMargin ? '2rem' : 0 }}>
      <StyledRoot sx={{ backgroundColor: '#121212' }}>
        <Container className="main-banner" sx={{ pb: 4, px: 2 }}>
          <Typography className="featured-text" pb={4}>
            Select your Brand!
          </Typography>
          <Stack>
            <Slider {...carouselSettings}>
              {brands?.brands?.map((brand, key) => (
                <Stack key={key}>
                  {brand && brand?.logo && (
                    <Link to={`/allproducts?id=${brand?._id}`}>
                      <Card className="CardTransform brand">
                        <CardMedia component="img" className="brand-img" src={brand?.logo[0]?.url} alt="Card Image" />
                      </Card>
                    </Link>
                  )}
                  <Typography color={'white'} textAlign={'center'} pt={1} fontSize={12}>
                    {brand?.name}
                  </Typography>
                </Stack>
              ))}
            </Slider>
            <Box display={'flex'} justifyContent={'center'} mt={5}>
              <Link to={'/allproducts'} style={{ textDecoration: 'none' }}>
                <Button className="all-brand-btn" sx={{ px: 6 }}>
                  VIEW ALL CARS
                </Button>
              </Link>
            </Box>
          </Stack>
        </Container>
      </StyledRoot>
    </div>
  );
}
