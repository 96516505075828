import { TOKEN_PREFIX } from '../server/api/http';
import { useDecryptData, useEncryptData } from './useDecryptEncrypt';

export const useSetToken = (token) => {
  // const encryptedToken = useEncryptData(token);
  const encryptedToken = useEncryptData(token?.split(`_${TOKEN_PREFIX}_`)[0]);

  sessionStorage.setItem(TOKEN_PREFIX, encryptedToken);
};

export const useGetToken = () => {
  const encryptedToken = sessionStorage.getItem(TOKEN_PREFIX);
  const decryptData = useDecryptData(encryptedToken);
  return decryptData;
};

export const useSetRole = (role, name) => {
  const currentRole = role;

  const encryptedRole = useEncryptData(currentRole);

  sessionStorage.setItem('currentRole', encryptedRole);
  sessionStorage.setItem('user', name);
};

export const useGetUser = () => {
  const user = sessionStorage.getItem('user');
  return user;
};

export const useGetRoles = () => {
  const encryptedRole = sessionStorage.getItem('currentRole');
  const { isSuccess: successRole, token: role } = useDecryptData(encryptedRole);

  return {
    successRole,
    role,
  };
};
