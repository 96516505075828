import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { useDevice } from '../../../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function Feature() {
  const { isMobile } = useDevice();

  // const [scrollValue, setScrollValue] = useState();

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollValue(window.scrollY);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const features = [
    {
      image: '/assets/images/Feature/finance.png',
      heading: 'Easy Finance',
      description: 'Unlock seamless car ownership with hassle-free and flexible financing options',
    },
    {
      image: '/assets/images/Feature/warrenty.png',
      heading: 'Warranty*',
      description:
        ' Drive with confidence knowing our selected cars come with a guarantee - a testament to quality and reliability.',
    },
    {
      image: '/assets/images/Feature/paperWork.png',
      heading: 'Quick Paper Works',
      description:
        'Say goodbye to paperwork headaches.We ensure swift and hassle-free documentation for your convenience.',
    },
    {
      image: '/assets/images/Feature/bestPrice.png',
      heading: 'Best Price',
      description: 'Experience fair and best pricing - your assurance that you are getting the best deal',
    },
    {
      image: '/assets/images/Feature/quality.png',
      heading: 'Quality Checks',
      description: ' Our commitment to excellence is evident in rigorous quality checks.',
    },
    {
      image: '/assets/images/Feature/exchange.png',
      heading: 'Exchange Opportunity',
      description: 'Upgrade your driving experience effortlessly with our convenient exchange opportunities',
    },
  ];

  return (
    <div>
      <StyledRoot sx={{ marginBottom: isMobile ? '2rem' : 0 }}>
        <Container
          className=""
          maxWidth="lg"
          sx={{
            paddingTop: { xs: '0', sm: '45px' },
            paddingBottom: { xs: '0', sm: '125px' },
            paddingLeft: { xs: '0', sm: '16px' },
            paddingRight: { xs: '0', sm: '16px' },
          }}
        >
          <Grid container direction="row" className="bg-color">
            <Box display={'flex'} justifyContent={'center'} width={'100%'}>
              <img src={`/assets/images/Home/focusFav.png`} alt="" />
            </Box>
            <Typography className="feat-text" pt={3} pb={4}>
              Why you should visit
              <br />
              FOCUS MOTORS?
            </Typography>
            {features?.map((feature, key) => (
              <Grid item xs={6} md={4} lg={4} mb={3} key={key}>
                <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                  <img src={feature?.image} alt="" className="feat-img" />
                </Box>
                <Typography className="feat-text-sub" fontSize={isMobile ? 15 : 20}>
                  {feature?.heading}
                </Typography>
                <Typography className="feat-text-des" fontSize={isMobile ? 12 : 18}>
                  {feature?.description}
                </Typography>
              </Grid>
            ))}
            {/* <Box display={'flex'} justifyContent={'center'} width={'100%'} my={6}> */}
            {/* <video height={'100px'} autoPlay muted controls={false} className="upgrade-video">
                <source src="/assets/images/Feature/upgrade.mp4" type="video/mp4" />
                <track kind="captions" src="your-captions-file.vtt" srcLang="en" label="English" />
              </video> */}
            {/* <img
                src={isMobile ? `/assets/images/Feature/upgrade.png` : `/assets/images/Feature/upgradeWeb.png`}
                alt=""
              /> */}
            {/* </Box> */}
          </Grid>
        </Container>
      </StyledRoot>
    </div>
  );
}
