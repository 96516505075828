import { createSlice } from '@reduxjs/toolkit';
import { createAppointment, getAllSlot, getSlotDetail } from '../api/slot';

export const slotSlice = createSlice({
  name: 'slot',
  initialState: {
    loading: false,
    error: {},
    // slot
    slotList: {},
    slotDropList: [],
    slotDetails: {},
  },
  reducers: {
    clearSlotError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create slot details
    [createAppointment.pending]: (state) => {
      state.loading = true;
    },
    [createAppointment.fulfilled]: (state, action) => {
      state.loading = false;
      state.slotDetails = action.payload;
      state.error = {};
    },
    [createAppointment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all slot
    [getAllSlot.pending]: (state) => {
      state.loading = true;
    },
    [getAllSlot.fulfilled]: (state, action) => {
      state.loading = false;
      state.slotList = action.payload;
      state.error = {};
    },
    [getAllSlot.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // slotedetails
    [getSlotDetail.pending]: (state) => {
      state.loading = true;
    },
    [getSlotDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.slotDetails = action.payload;
      state.error = {};
    },
    [getSlotDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearSlotError } = slotSlice.actions;

export default slotSlice.reducer;
