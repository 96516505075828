import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Card, CardMedia, Container, Grid, Typography } from '@mui/material';
import Slider from 'react-slick'; // Import the Slider component from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import icons from Material-UI Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDevice } from '../../../hooks/useResponsive';
import { getAllVehiclesByFeatured } from '../../../server/api/category';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

FeaturedCars.propTypes = {
  addMargin: propTypes.any,
};
export default function FeaturedCars({ addMargin }) {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  const { allVehicleList } = useSelector((state) => ({
    allVehicleList: state?.category?.allVehicleListByFeatured?.vehicles,
  }));

  console.log(allVehicleList);

  useEffect(() => {
    dispatch(getAllVehiclesByFeatured());
  }, [dispatch]);

  // Custom left arrow component
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left leftrArrow-feature"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon sx={{ fontSize: 30 }} /> {/* Use the ChevronLeftIcon from Material-UI Icons */}
      </div>
    );
  };

  // Custom right arrow component
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow-feature"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon sx={{ fontSize: 30 }} /> {/* Use the ChevronRightIcon from Material-UI Icons */}
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // Custom left arrow component
    nextArrow: <CustomNextArrow />, // Custom right arrow component
  };

  return (
    <div style={{ marginBottom: addMargin ? '2rem' : 0 }}>
      {allVehicleList?.length > 0 && (
        <StyledRoot sx={{ backgroundColor: '#121212' }}>
          <Container className="main-banner" sx={{ pb: 4, px: 2 }}>
            <Typography className="featured-text" pb={2}>
              Featured Cars
            </Typography>
            <Slider {...carouselSettings}>
              {allVehicleList?.map((car, key) => (
                <div key={key} className="feature-slider">
                  <Card className="CardTransform-feature-cars">
                    {car && car?.images[0] ? (
                      <CardMedia
                        component="img"
                        className="card-img-feature samesize"
                        src={car?.images[0]?.url}
                        alt="Card Image"
                      />
                    ) : (
                      <CardMedia
                        component="img"
                        className="card-img-feature samesize"
                        src={'/assets/images/Feature/black.png'}
                        alt="Card Image"
                      />
                    )}
                    <Box className="featured-img">
                      <Link to={`/product-single/${car?._id}`} style={{ textDecoration: 'none' }}>
                        <Box pr={2}>
                          <Typography variant="caption" fontWeight={600} className="featured-img-text">
                            {' '}
                            {car?.makeYear && <CalendarMonthIcon style={{ fontSize: '13px' }} />}
                            {car?.makeYear}
                          </Typography>
                          <Typography variant="body1" fontSize={25} className="featured-img-text">
                            {' '}
                            {car?.name}
                          </Typography>
                          <Typography variant="body1" className="featured-img-text">
                            {' '}
                            {car?.variant?.name}
                          </Typography>
                          {car?.fuelType && (
                            <Typography variant="body2" fontWeight={600} className="featured-img-text">
                              {' '}
                              Fuel
                            </Typography>
                          )}
                          <Typography variant="body1" className="featured-img-text">
                            {' '}
                            {car?.fuelType}
                          </Typography>
                          <Grid display={'flex'} justifyContent={'end'} mt={1}>
                            {car && car?.brand && car?.brand?.logo && (
                              <CardMedia
                                component="img"
                                className=""
                                src={car?.brand?.logo[0]?.url}
                                alt="Card Image"
                                style={{ width: '35px' }}
                              />
                            )}
                          </Grid>
                        </Box>
                      </Link>
                    </Box>
                  </Card>
                </div>
              ))}
            </Slider>
          </Container>
        </StyledRoot>
      )}
    </div>
  );
}
