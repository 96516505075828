import React from 'react';
import { Container, Button, Box, Stack, TextField, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useDevice } from '../hooks/useResponsive';
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import { createLead } from '../server/api/lead';
import SubmitModal from './popup';

export default function DreamCar() {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  const [modal, setModal] = React.useState(false);

  const CustomButton = styled(Button)({
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    height: '50px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: '23px',
    fontWeight: '900',
    marginTop: '15px',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });

  const CustomButtonWeb = styled(Button)({
    width: '25%',
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    height: '40px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: '20px',
    fontWeight: '900',
    marginTop: '15px',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      phone: '',
      pincode: '',
      message: '',
    },
  });

  const onSubmit = async (value, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        leadType: 'dream_car',
        name: value?.name,
        phone: value?.phone,
        address: {
          pincode: parseInt(value?.pincode, 10),
        },
        message: value?.message,
      },
      dispatch,
      reset,
      setModal,
    };
    dispatch(createLead(credentials));
  };

  return (
    <>
      <MetaHelmet title={'Dream Car'} companyName={'Focus Motors'} />
      <SubmitModal open={modal} close={() => setModal(false)} />
      <Box className="bg-color">
        <Container maxWidth="lg" sx={{ padding: '0px 5px 0px 5px' }}>
          <Stack>
            <img src={isMobile ? 'assets/images/Sell/dreamBanner.png' : 'assets/images/Sell/Dreamcar.png'} alt="" />
          </Stack>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Box mt={3} px={0}>
              <Grid container>
                <Grid item md={isMobile ? ' ' : 4} xs={12}>
                  <TextField
                    fullWidth
                    name="name"
                    placeholder="Enter your name"
                    id="fullWidth"
                    type="text"
                    className="custom-field-sell"
                    {...register('name', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
                <Grid md={isMobile ? ' ' : 4} px={isMobile ? '' : 1} xs={12}>
                  <TextField
                    fullWidth
                    name="phone"
                    placeholder="Enter your phone"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('phone', {
                      required: { value: false, message: '' },
                      maxLength: {
                        value: 15,
                        message: 'Phone must not exceed 15 characters',
                      },
                      minLength: {
                        value: 9,
                        message: 'Phone must have at least 9 characters',
                      },
                    })}
                  />
                </Grid>
                <Grid md={isMobile ? ' ' : 4} mx={0} xs={12}>
                  <TextField
                    fullWidth
                    name="pincode"
                    placeholder="Enter your pincode"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('pincode', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
                <Grid md={isMobile ? ' ' : 12} xs={12}>
                  <TextField
                    fullWidth
                    name="message"
                    placeholder="Tell Us about your Dream Car!"
                    id="fullWidth"
                    type="text"
                    className="custom-field-sell"
                    rows={3}
                    multiline
                    {...register('message', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
              </Grid>
              {isMobile ? (
                <CustomButton type="submit" variant="contained" className="request-btn" fullWidth>
                  Submit
                </CustomButton>
              ) : (
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                  <CustomButtonWeb type="submit" variant="contained" className="">
                    Submit
                  </CustomButtonWeb>
                </Box>
              )}
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
}
