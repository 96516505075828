import * as React from 'react';
// import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DehazeIcon from '@mui/icons-material/Dehaze';
// import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';

// eslint-disable-next-line import/no-unresolved
// import Logo from 'components/logo';
import { Link } from 'react-router-dom';
import HomeIcon from '../appBarIcons/home';
import BuyIcon from '../appBarIcons/buy';
import SellIcon from '../appBarIcons/sell';
import DreamCarIcon from '../appBarIcons/dreamCar';

export const TemporaryDrawer = () => {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navItems = [
    {
      name: 'Home',
      link: '/home',
    },
    {
      name: 'Buy',
      link: '/allproducts',
    },
    {
      name: 'Sell',
      link: '/sell',
    },
    {
      name: 'Dream Car',
      link: '/dream',
    },
  ];

  const list = (anchor) => (
    <Box
      sx={{
        width: 250,
        backgroundColor: '#121212',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box>
        {/* <Logo /> */}
        <List>
          {navItems?.map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {text?.name === 'Home' ? (
                    <HomeIcon />
                  ) : text?.name === 'Buy' ? (
                    <BuyIcon />
                  ) : text?.name === 'Sell' ? (
                    <SellIcon />
                  ) : (
                    <DreamCarIcon />
                  )}
                </ListItemIcon>
                <Link to={text?.link} style={{ textDecoration: 'none' }}>
                  <ListItemText primary={text?.name} className="nav-text" />
                </Link>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <CloseIcon fontSize="large" style={{ paddingTop: '10px', paddingRight: '10px', color: 'white' }} />
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} style={{ color: 'white' }}>
            {' '}
            <DehazeIcon fontSize="large" />
          </Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} className="nav-drawer">
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

// TemporaryDrawer.propTypes = {

// };
