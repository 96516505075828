import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { useDevice } from 'hooks/useResponsive';
import useCloudinary from '../../hooks/useCloudinaryImage';

ProductCard.propTypes = {
  product: PropTypes.any,
};
export default function ProductCard({ product }) {
  const capitalizeFirstLetter = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);
  const image = [{ img: '/assets/car.png' }];
  const isMobile = useDevice()?.isMobile;
  const navigate = useNavigate();

  const handleRouter = () => {
    // dispatch(clearVehicleDetails());
    if (product?.isSold !== true) {
      navigate(`/product-single/${product?._id}`);
    }
  };

  return (
    <>
      <Stack style={{ minHeight: '210px', position: 'relative' }} onClick={handleRouter}>
        <Box sx={{ filter: product?.isSold === true && 'grayscale(100%)' }}>
          <Stack position="relative">
            {product?.images?.[0]?.url ? (
              <img
                // eslint-disable-next-line react-hooks/rules-of-hooks
                src={useCloudinary(product.images[0].url, 'w_600')}
                alt=""
                style={{
                  borderRadius: '15px',
                  // height: isMobile ? 'auto' : 'auto',
                  aspectRatio: 4 / 3,
                  objectFit: 'cover',
                }}
              />
            ) : (
              <img
                src={image[0]?.img}
                alt=""
                style={{
                  borderRadius: '15px',
                  height: isMobile ? 'auto' : 'auto',
                  aspectRatio: 4 / 3,
                  objectFit: 'cover',
                }}
              />
            )}
            {product?.makeYear && product.makeYear !== 0 && (
              <Grid className="car-year" display="flex" alignItems="center">
                <CalendarMonthIcon style={{ fontSize: '10px', color: '#121212', marginRight: '2px' }} />
                <Typography fontSize={10} color="#121212" fontWeight={800} sx={{ paddingTop: '2px' }}>
                  {product.makeYear}
                </Typography>
              </Grid>
            )}
          </Stack>
          <Typography fontWeight={300} fontSize={16} color="#FFA800" pl={1} pt={1} minHeight={0}>
            {product?.name}
          </Typography>
          <Typography fontWeight={800} fontSize={12} color="white" pl={1} letterSpacing={1}>
            {product?.variant?.name}
          </Typography>
          <Typography fontWeight={200} fontSize={12} color="white" pl={1} letterSpacing={0.5}>
            Fuel: {product?.fuelType ? capitalizeFirstLetter(product.fuelType) : ''}
          </Typography>
        </Box>

        {product?.isSold ? (
          <img
            src="/assets/images/Product/sold.png"
            alt="Sold"
            style={{ position: 'absolute', right: -5, bottom: 60, width: '100px', height: '100px' }}
          />
        ) : product?.isBooked ? (
          <img
            src="/assets/images/Product/booked.png"
            alt="Booked"
            style={{ position: 'absolute', bottom: 70, right: 10 }}
          />
        ) : null}
      </Stack>
    </>
  );
}
