import { createAsyncThunk } from '@reduxjs/toolkit';
import { activeSnack } from '../store/common';
import { get, post } from './http';

export const getFilteredVehicles = createAsyncThunk('/vehicle/user/filter', async ({ state, catId, page, limit }) => {
  try {
    const URL = `/vehicle/user/filter?category=${catId ?? ''}&page=${page ?? 1}&limit=${limit ?? 10}`;
    const response = await post(URL, state);
    // dispatch(activeSnack({ type: 'success', message: 'Enquiry Submitted successfully' }));
    return response;
    // return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    // dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const getAllVehicles = createAsyncThunk('/vehicle/all', async ({ page, limit, search }) => {
  try {
    const URL = `/vehicle/all?page=${page ?? 1}&limit=${limit ?? 10}&search=${search ?? ''}`;
    const response = await get(URL);
    // dispatch(activeSnack({ type: 'success', message: 'Enquiry Submitted successfully' }));
    return response;
    // return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    // dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const getVehicleDetails = createAsyncThunk('vehicle/single', async ({ vehicleId, dispatch }) => {
  try {
    const response = await get(`/vehicle/${vehicleId}/`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const getRelatedVehicles = createAsyncThunk('vehicle/user/related', async ({ vehicleId, dispatch }) => {
  try {
    const response = await get(`/vehicle/user/related/${vehicleId}`);
    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllBrands = createAsyncThunk('/brand/all', async ({ dispatch, page, limit, searchText }) => {
  try {
    const URL = `/brand/all/?page=${page ?? 1}&limit=${limit ?? 10}&search=${searchText ?? ''}`;

    const response = await get(URL);
    if (response) {
      return {
        response,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const getAllModels = createAsyncThunk('/model/all', async ({ dispatch, page, limit, brand }) => {
  try {
    const URL = `/model/all/?page=${page ?? 1}&limit=${limit ?? 10}&brand=${brand ?? ''}`;
    const response = await get(URL);
    if (response) {
      return {
        response,
      };
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
