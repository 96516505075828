import { createSlice } from '@reduxjs/toolkit';
import { createLead } from '../api/lead';

// const covertToJSON = (data) => JSON.parse(JSON.stringify(data));

export const leadSlice = createSlice({
  name: 'lead',
  initialState: {
    loading: false,
    error: {},
    leadList: {},
  },
  extraReducers: {
    // create lead
    [createLead.pending]: (state) => {
      state.loading = true;
    },
    [createLead.fulfilled]: (state, action) => {
      state.leadList = action.payload;
      state.error = {};
    },
    [createLead.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default leadSlice.reducer;
