import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import SimpleLayout from './layouts/simple';
//
import Page404 from './pages/Page404';
import HomePage from './pages/Home';
import SellPage from './pages/Sell';
import DreamCar from './pages/dreamCar';
import ProductSinglePage from './pages/ProductSingle';
import SingleCategory from './pages/SingleCategory';
import AllProducts from './pages/AllProducts';
import VehicleSearch from './pages/search';

// ----------------------------------------------------------------------

export default function Router() {
  const routesList = [
    // public
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        {
          path: '/',
          children: [
            {
              path: 'home',
              element: <HomePage />,
            },
            {
              path: 'allproducts',
              element: <AllProducts />,
            },
            {
              path: 'sell',
              element: <SellPage />,
            },
            {
              path: 'dream',
              element: <DreamCar />,
            },
            {
              path: 'product-single/:id',
              element: <ProductSinglePage />,
            },
            {
              path: 'category-single/:id',
              element: <SingleCategory />,
            },
            {
              path: 'search',
              element: <VehicleSearch />,
            },
            // add other routes here
          ],
        },

        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

    // handle notfound routes
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ];

  const routes = useRoutes(routesList);

  return routes;
}
