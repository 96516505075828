import React from 'react';

const SellIcon = () => (
  <svg width="35" height="17" viewBox="0 0 35 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9997 4.96584L19.9997 4.31563C20.0023 3.34806 19.6205 2.41908 18.9383 1.73297C18.256 1.04686 17.3292 0.659807 16.3616 0.656927L4.57136 0.67241C3.6038 0.672556 2.6759 1.05698 1.99173 1.74116C1.30756 2.42533 0.923128 3.35323 0.922982 4.32079L0.922983 13.145C0.923129 14.1126 1.30756 15.0405 1.99173 15.7247C2.6759 16.4088 3.6038 16.7933 4.57136 16.7934L16.3462 16.7934C17.3137 16.7933 18.2416 16.4088 18.9258 15.7247C19.61 15.0405 19.9944 14.1126 19.9945 13.145L19.9945 12.7322L22.2341 12.7219L24.0093 10.9467L25.5574 12.4948L27.4616 10.5906L28.3027 11.4318L31.2751 11.4421L34.3713 8.34588L30.9913 4.96584H19.9997ZM32.3072 8.34588L30.6868 9.96623L28.8652 9.9714L27.4203 8.52649L25.5213 10.4255L23.9732 8.8774L21.5943 11.2563L18.498 11.2563L18.5342 13.1502C18.5338 13.7304 18.3032 14.2867 17.8929 14.6969C17.4827 15.1072 16.9263 15.3378 16.3462 15.3382L4.54556 15.3124C3.96538 15.312 3.40906 15.0814 2.99881 14.6711C2.58856 14.2609 2.35793 13.7046 2.35757 13.1244V4.28983C2.35793 3.70965 2.58856 3.15333 2.99881 2.74308C3.40906 2.33283 3.96538 2.10219 4.54556 2.10183L16.3204 2.10183C16.9005 2.10219 17.4569 2.33283 17.8671 2.74308C18.2774 3.15333 18.508 3.70965 18.5084 4.28983L18.5084 6.35398L30.3875 6.42622L32.3072 8.34588Z"
      fill="#FFA800"
    />
  </svg>
);

export default SellIcon;
