import React from 'react';
import propTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Card, CardMedia, Container } from '@mui/material';
import Slider from 'react-slick'; // Import the Slider component from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Import icons from Material-UI Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDevice } from '../../../hooks/useResponsive';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

Banner.propTypes = {
  addMargin: propTypes.any,
};
export default function Banner({ addMargin }) {
  const { isMobile } = useDevice();

  // Custom left arrow component
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon /> {/* Use the ChevronLeftIcon from Material-UI Icons */}
      </div>
    );
  };

  // Custom right arrow component
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon /> {/* Use the ChevronRightIcon from Material-UI Icons */}
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // Custom left arrow component
    nextArrow: <CustomNextArrow />, // Custom right arrow component
  };

  const banners = [
    { mobBanner: '/assets/images/Banner/mainBanner.png', webBanner: '/assets/images/Banner/webanner1.jpg' },
    { mobBanner: '/assets/images/Banner/mainBanner2.jpg', webBanner: '/assets/images/Banner/webanner2.jpg' },
    { mobBanner: '/assets/images/Banner/mainBanner3.png', webBanner: '/assets/images/Banner/webanner3.jpg' },
  ];

  return (
    <div style={{ marginBottom: addMargin ? '2rem' : 0 }}>
      <StyledRoot sx={{ backgroundColor: '#121212' }}>
        <Container className="main-banner" sx={{ pb: 4, px: 2, pt: 0 }}>
          <Slider {...carouselSettings}>
            {banners?.map((banner, key) => (
              <div key={key}>
                <Card className="CardTransform">
                  <CardMedia
                    component="img"
                    className=" samesize"
                    src={isMobile ? banner?.mobBanner : banner?.webBanner}
                    alt="Card Image"
                  />
                </Card>
              </div>
            ))}
          </Slider>
        </Container>
      </StyledRoot>
    </div>
  );
}
