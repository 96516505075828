import React from 'react';
import Container from '@mui/material/Container';
import { Grid, Box, Typography, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HomeIcon from '@mui/icons-material/Home';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import SellIcon from '@mui/icons-material/Sell';
import AirportShuttleRoundedIcon from '@mui/icons-material/AirportShuttleRounded';
import { useLocation, useNavigate } from 'react-router-dom';
import ReviewCard from '../../segment/@common/Review/reviewCard';
import { useDevice } from '../../hooks/useResponsive';

const Footer = () => {
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const location = useLocation();

  console.log(location?.pathname);
  const isProductSinglePage = /^\/product-single\/[a-zA-Z0-9]+$/.test(location?.pathname);
  console.log(isProductSinglePage);

  const handleScrollToTop = () => {
    navigate('/search');
  };

  console.log(isMobile);

  // const [scrollValue, setScrollValue] = useState();

  // React.useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollValue = window.scrollY;
  //     setScrollValue(scrollValue);
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // console.log('data :', scrollValue);

  // const footerItems = [
  //   {
  //     itemName: 'Home',
  //     link: '/home',
  //   },
  //   {
  //     itemName: 'Products',
  //     link: '/products',
  //   },

  //   {
  //     itemName: 'Estimate',
  //     link: '/estimate',
  //   },
  //   {
  //     itemName: 'About',
  //     link: '/about',
  //   },
  // ];

  const footerItemsMobile = [
    {
      itemName: 'Home',
      pathName: '/home',
      activeImage: '/assets/appBarIcons/home.png',
      inActiveImage: '/assets/appBarIcons/home.png',
      alt: '1',
    },
    {
      itemName: 'Buy',
      pathName: '/allproducts',
      activeImage: '/assets/appBarIcons/buy.png',
      inActiveImage: '/assets/appBarIcons/buy.png',
      alt: '2',
    },
    {
      itemName: 'Sell',
      pathName: '/sell',
      activeImage: '/assets/appBarIcons/sell.png',
      inActiveImage: '/assets/appBarIcons/sell.png',
      alt: '3',
    },
    {
      itemName: 'Dream Car',
      pathName: '/dream',
      activeImage: '/assets/appBarIcons/dreamCar.png',
      inActiveImage: '/assets/appBarIcons/dreamCar.png',
      alt: '4',
    },
  ];

  return (
    <>
      {location?.pathname !== '/search' && (
        <Grid className="bg-color" sx={{ pb: isMobile && isProductSinglePage && 8 }}>
          <Container maxWidth="lg">
            <Typography width={'100%'} textAlign={'center'} color={'#F8971C'} fontSize={22} fontWeight={700} py={4}>
              Happy Customers 😊
            </Typography>
            <ReviewCard />
            {isMobile ? (
              <Grid spacing={2} pb={13}>
                <Box mt={10} width={'100%'} display={'flex'} justifyContent={'center'}>
                  <Link href={'/home'}>
                    <img
                      className="footerlogo"
                      src={`/assets/focus-logo.png`}
                      alt="Logo"
                      style={{ height: '96px', width: '156px' }}
                    />
                  </Link>
                </Box>
                <Box color={'white'} width={'100%'}>
                  <Typography textAlign={'center'} pt={2}>
                    Thambanangady, Pandikkad
                    <br /> Malappuram - Kerala
                  </Typography>
                  <Grid display={'flex'} justifyContent={'center'} pt={2}>
                    <Box display={'flex'} mr={2}>
                      <LocalPhoneIcon fontSize="small" sx={{ color: '#FFA800' }} />{' '}
                      <Typography pl={0}>+91 8590887713</Typography>
                    </Box>
                    <Box display={'flex'}>
                      <MailOutlineIcon fontSize="small" sx={{ color: '#FFA800' }} />{' '}
                      <Typography pl={1}>info@focusmotors.in</Typography>
                    </Box>
                  </Grid>
                  <Box display={'flex'} justifyContent={'center'} color={'gray'} mt={3}>
                    <Typography pr={1} color={'white'}>
                      Follow Us
                    </Typography>
                    <a href="https://www.instagram.com/focus_motors_/">
                      <InstagramIcon fontSize="medium" className="p-2" color="disabled" />
                    </a>
                    <a href="https://www.facebook.com/search/top?q=focus%20motors">
                      <FacebookIcon fontSize="medium" className="p-2" color="disabled" />
                    </a>
                    {/* <a href=''>
                  <TwitterIcon fontSize="medium" className="p-2" />
                  </a> */}
                    {/* <a href=''>
                  <LinkedInIcon fontSize="medium" className="p-2" />
                  </a> */}
                    <a href="https://www.youtube.com/@focusmotors">
                      <YouTubeIcon fontSize="medium" className="p-2" color="disabled" />
                    </a>
                  </Box>

                  <Typography fontSize={10} textAlign={'center'} pt={3}>
                    Copyrights © Focus Motors | Powered by{' '}
                    <a href="https://osperb.com/" style={{ textDecoration: 'none' }}>
                      <span style={{ color: '#579AFF' }}>osperb</span>
                    </a>
                  </Typography>
                </Box>
                {/* <Stack>
              <Typography color={'white'} textAlign={'center'} width={'100%'} pt={2}>
                Thambanangady, Pandikkad
                <br /> Malappuram - Kerala
              </Typography>
              <Grid display={'flex'} justifyContent={'center'} margin={'auto'} pt={3}>
                <Box sx={{ display: 'flex' }}>
                  <LocalPhoneIcon sx={{ color: '#FFA800', paddingRight: '2px' }} fontSize="small" />{' '}
                  <Typography color={'white'}>+91 8590887713</Typography>
                </Box>
                <Box sx={{ display: 'flex' }} marginLeft={2}>
                  <MailOutlineIcon sx={{ color: '#FFA800', paddingRight: '2px' }} fontSize="small" />{' '}
                  <Typography color={'white'}>info@focusmotors.in</Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
              >
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                    <Typography pr={1} color={'white'}>
                      Follow Us
                    </Typography>
                    <InstagramIcon fontSize="small" sx={{ marginRight: 1 }} />
                    <FacebookIcon fontSize="small" sx={{ marginRight: 1 }} />
                    <TwitterIcon fontSize="small" sx={{ marginRight: 1 }} />
                    <LinkedInIcon fontSize="small" sx={{ marginRight: 1 }} />
                    <YouTubeIcon fontSize="small" />
                  </Box>
                </Box>
                <Typography color={'white'} fontSize={11} pt={2}>
                  Copyrights © Focus Motors | Powered by <span style={{ color: '#579AFF' }}>osperb</span>
                </Typography>
              </Grid>
            </Stack> */}
              </Grid>
            ) : (
              <Box py={13} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Box ml={10}>
                  <Link href={'/home'}>
                    <img
                      className="footerlogo"
                      src={`/assets/focus-logo.png`}
                      alt="Logo"
                      style={{ height: '96px', width: '156px' }}
                    />
                  </Link>
                </Box>
                <Box color={'white'} ml={10}>
                  <Typography>
                    Thambanangady, Pandikkad
                    <br /> Malappuram - Kerala
                  </Typography>
                  <Grid display={'flex'} pt={2}>
                    <Box display={'flex'} mr={2}>
                      <LocalPhoneIcon fontSize="small" sx={{ color: '#FFA800' }} />{' '}
                      <Typography pl={1}>+91 8590887713</Typography>
                    </Box>
                    <Box display={'flex'}>
                      <MailOutlineIcon fontSize="small" sx={{ color: '#FFA800' }} />{' '}
                      <Typography pl={1}>info@focusmotors.in</Typography>
                    </Box>
                  </Grid>
                  <Box display={'flex'} color={'gray'} mt={3}>
                    <Typography pr={1} color={'white'}>
                      Follow Us
                    </Typography>
                    <a href="https://www.instagram.com/focus_motors_/">
                      <InstagramIcon fontSize="medium" className="p-2" color="disabled" />
                    </a>
                    <a href="https://www.facebook.com/search/top?q=focus%20motors">
                      <FacebookIcon fontSize="medium" className="p-2" color="disabled" />
                    </a>
                    {/* <a href=''>
                  <TwitterIcon fontSize="medium" className="p-2" />
                  </a> */}
                    {/* <a href=''>
                  <LinkedInIcon fontSize="medium" className="p-2" />
                  </a> */}
                    <a href="https://www.youtube.com/@focusmotors">
                      <YouTubeIcon fontSize="medium" className="p-2" color="disabled" />
                    </a>
                  </Box>
                  <Typography fontSize={10} pt={2}>
                    Copyrights © Focus Motors | Powered by <span style={{ color: '#579AFF' }}>osperb</span>
                  </Typography>
                </Box>
              </Box>
            )}
          </Container>
        </Grid>
      )}
      {/* ornge section */}
      {/* {location?.pathname !== '/booking' && (
        <Grid
          className={`floating-card ${scrollValue >= 1350 ? 'active' : ''}`}
          sx={{
            backgroundColor: colors.primary,
            borderRadius: '5px 5px 0px 0px',
            padding: '12px',
            display: { sx: 'flex', sm: 'none' },
            position: 'fixed',
            bottom: '76px',
            width: '100%',
          }}
        >
          <Link to={'/booking'} style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
              <IconButton sx={{ marginRight: 1 }}>
                <img src={`/assets/images/Header/icon.png`} alt=" 1" style={{ width: '16px', height: '18px' }} />
              </IconButton>
              <Typography className="click" variant="body1" sx={{ fontSize: '16px', fontWeight: '300' }}>
                Click here to {''}
                <Typography
                  className="book"
                  component="span"
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: '#fff', fontSize: '16px' }}
                >
                  Book A Free Home Visit
                </Typography>
              </Typography>
            </Box>
            <Typography
              variant="body1"
              className="select"
              sx={{ color: '#fff', fontWeight: '200', fontSize: '12px', pl: 5.5 }}
            >
              Select your convenient time slot in the next step.
            </Typography>
          </Link>
        </Grid>
      )} */}
      {/* white section */}
      <Grid
        container
        spacing={1}
        className="app-bar"
        sx={{
          padding: '13px 13px',
          display: { sx: 'flex', sm: 'none' },
          flexDirection: { sx: 'row' },
          position: 'fixed',
          bottom: 20,
          left: 0,
          right: 0,
          backgroundColor: '#1F1F1F',
          borderRadius: '10px',
          boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
          justifyContent: 'space-between',
          width: '90%',
          margin: 'auto',
        }}
      >
        {footerItemsMobile?.slice(0, 2)?.map((item, key) => (
          <Grid key={key}>
            <Link href={item?.pathName} style={{ textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'column' },
                  alignItems: 'center',
                  marginLeft: '5px',
                }}
              >
                {item?.itemName === 'Home' ? (
                  <HomeIcon fontSize="small" className="head-icon-color" />
                ) : (
                  <AirportShuttleRoundedIcon fontSize="small" className="head-icon-color" />
                )}
                <Typography textAlign={'center'} color={'#E3E3E3'} fontSize={12} fontWeight={400}>
                  {item?.itemName}
                </Typography>
              </Box>
            </Link>
          </Grid>
        ))}
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={handleScrollToTop}
          // sx={{ backgroundColor: '#373737', borderRadius: '50%', width: '12%' }}
        >
          <img src={'/assets/appBarIcons/search.png'} alt="" width={'35px'} height={'38px'} />
        </Box>
        {footerItemsMobile?.slice(2, footerItemsMobile?.length)?.map((item, key) => (
          <Grid key={key}>
            <Link href={item?.pathName} style={{ textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'column' },
                  alignItems: 'center',
                  marginRight: '5px',
                }}
              >
                {item?.itemName === 'Sell' ? (
                  <SellIcon fontSize="small" className="head-icon-color" />
                ) : (
                  <DriveEtaIcon fontSize="small" className="head-icon-color" />
                )}
                <Typography sx={{ textAlign: 'center', color: 'white', fontSize: '12px' }}>{item?.itemName}</Typography>
              </Box>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Footer;
