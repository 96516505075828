import { createSlice } from '@reduxjs/toolkit';
import { createFeedback } from '../api/feedback';

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    loading: false,
    error: {},
    feedbackDetails: {},
  },
  reducers: {
    clearFeedbackError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create feedback details
    [createFeedback.pending]: (state) => {
      state.loading = true;
    },
    [createFeedback.fulfilled]: (state, action) => {
      state.loading = false;
      state.feedbackDetails = action.payload;
      state.error = {};
    },
    [createFeedback.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearFeedbackError } = feedbackSlice.actions;

export default feedbackSlice.reducer;
