import { createSlice } from '@reduxjs/toolkit';
import {
  getAllCategories,
  getAllCategoriesIncludeVehicle,
  getAllVehiclesByFeatured,
  getCategoryDetail,
  getVehiclesByCategory,
} from '../api/category';

// const covertToJSON = (data) => JSON.parse(JSON.stringify(data));

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    loading: false,
    error: {},
    categoryList: {},
    categoryDetails: {},
    categoryIncludeVehicle: {},
    allVehicleListByFeatured: [],
  },
  reducers: {
    clearCategoryError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get categories
    [getAllCategories.pending]: (state) => {
      state.loading = true;
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.categoryDetails = action.payload;
      state.categoryList = action.payload;
      state.error = {};
    },
    [getAllCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllCategoriesIncludeVehicle.pending]: (state) => {
      state.loading = true;
    },
    [getAllCategoriesIncludeVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      // state.categoryDetails = action.payload;
      state.categoryIncludeVehicle = action.payload;
      state.error = {};
    },
    [getAllCategoriesIncludeVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get vehicles by category
    [getVehiclesByCategory.pending]: (state) => {
      state.loading = true;
    },
    [getVehiclesByCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehiclesByCategory = action.payload;
      state.error = {};
    },
    [getVehiclesByCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single category details
    [getCategoryDetail.pending]: (state) => {
      state.loading = true;
    },
    [getCategoryDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.categoryDetails = action.payload;
      state.error = {};
    },
    [getCategoryDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllVehiclesByFeatured.pending]: (state) => {
      state.loading = true;
    },
    [getAllVehiclesByFeatured.fulfilled]: (state, action) => {
      state.loading = false;
      state.allVehicleListByFeatured = action.payload;
      state.error = {};
    },
    [getAllVehiclesByFeatured.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearCategoryError } = categorySlice.actions;

export default categorySlice.reducer;
