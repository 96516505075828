import { createSlice } from '@reduxjs/toolkit';
import {
  getFilteredVehicles,
  getVehicleDetails,
  getRelatedVehicles,
  getAllBrands,
  getAllModels,
  getAllVehicles,
} from '../api/vehicle';

// const covertToJSON = (data) => JSON.parse(JSON.stringify(data));

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    loading: false,
    error: {},
    allVehicleList: [],
    vehicleList: [],
    enquiryDropList: [],
    vehicleDetails: {},
    relatedVehicles: [],
    brands: [],
    models: [],
    allVehicleListByFeatured: [],
  },
  reducers: {
    clearVehicleError: (state) => {
      state.error = true;
    },
    clearVehicleDetails: (state) => {
      state.vehicleDetails = {};
    },
  },
  extraReducers: {
    // get all vehicles
    [getAllVehicles.pending]: (state) => {
      state.loading = true;
    },
    [getAllVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.allVehicleList = action.payload;
      state.error = {};
    },
    [getAllVehicles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get vehicles
    [getFilteredVehicles.pending]: (state) => {
      state.loading = true;
    },
    [getFilteredVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehicleList = action.payload;
      state.error = {};
    },
    [getFilteredVehicles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get related vehicles
    [getRelatedVehicles.pending]: (state) => {
      state.loading = true;
    },
    [getRelatedVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.relatedVehicles = action.payload;
      state.error = {};
    },
    [getRelatedVehicles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single vehicle details
    [getVehicleDetails.pending]: (state) => {
      state.loading = true;
    },
    [getVehicleDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehicleDetails = action.payload;
      state.error = {};
    },
    [getVehicleDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllBrands.pending]: (state) => {
      state.loading = true;
    },
    [getAllBrands.fulfilled]: (state, action) => {
      state.loading = false;
      state.brands = action.payload;
      state.error = {};
    },
    [getAllBrands.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllModels.pending]: (state) => {
      state.loading = true;
    },
    [getAllModels.fulfilled]: (state, action) => {
      state.loading = false;
      state.models = action.payload;
      state.error = {};
    },
    [getAllModels.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearVehicleError, clearVehicleDetails } = vehicleSlice.actions;

export default vehicleSlice.reducer;
