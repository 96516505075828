import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '1px solid #f8971c',
  boxShadow: 'none',
  borderRadius: '10px',
  height: '200px',
  textAlign: 'center',
  pt: 5,
};

const SubmitModalLead = ({ open, close }) => (
  <Modal open={open} onClose={close} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Submitted Successfully !
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Your application submitted successfully
      </Typography>
      <Box display={'flex'} justifyContent={'center'} mt={2}>
        <Button onClick={close} sx={{ background: '#ffa800', color: 'white' }}>
          Ok
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default SubmitModalLead;

SubmitModalLead.propTypes = {
  open: PropTypes.any,
  close: PropTypes.any,
};
