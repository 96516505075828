import React from 'react';

const HomeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.0123 7.99979C18.0123 7.73457 17.9069 7.48022 17.7194 7.29268C17.5319 7.10514 17.2775 6.99979 17.0123 6.99979C16.7471 6.99979 16.4927 7.10514 16.3052 7.29268C16.1176 7.48022 16.0123 7.73457 16.0123 7.99979H18.0123ZM4.01229 7.99979C4.01229 7.73457 3.90693 7.48022 3.71939 7.29268C3.53186 7.10514 3.2775 6.99979 3.01229 6.99979C2.74707 6.99979 2.49272 7.10514 2.30518 7.29268C2.11765 7.48022 2.01229 7.73457 2.01229 7.99979H4.01229ZM18.3053 10.7068C18.4939 10.8889 18.7465 10.9897 19.0087 10.9875C19.2709 10.9852 19.5217 10.88 19.7071 10.6946C19.8925 10.5092 19.9977 10.2584 20 9.99619C20.0022 9.73399 19.9014 9.48139 19.7193 9.29279L18.3053 10.7068ZM10.0123 0.999786L10.7193 0.292786C10.5318 0.105315 10.2775 0 10.0123 0C9.74712 0 9.49282 0.105315 9.30529 0.292786L10.0123 0.999786ZM0.305288 9.29279C0.209778 9.38503 0.133596 9.49538 0.0811869 9.61738C0.0287779 9.73939 0.00119157 9.87061 3.77567e-05 10.0034C-0.00111606 10.1362 0.0241857 10.2678 0.0744666 10.3907C0.124747 10.5136 0.199001 10.6253 0.292893 10.7192C0.386786 10.8131 0.498438 10.8873 0.621334 10.9376C0.744231 10.9879 0.87591 11.0132 1.00869 11.012C1.14147 11.0109 1.27269 10.9833 1.39469 10.9309C1.5167 10.8785 1.62704 10.8023 1.71929 10.7068L0.305288 9.29279ZM5.01229 19.9998H15.0123V17.9998H5.01229V19.9998ZM18.0123 16.9998V7.99979H16.0123V16.9998H18.0123ZM4.01229 16.9998V7.99979H2.01229V16.9998H4.01229ZM19.7193 9.29279L10.7193 0.292786L9.30529 1.70679L18.3053 10.7068L19.7193 9.29279ZM9.30529 0.292786L0.305288 9.29279L1.71929 10.7068L10.7193 1.70679L9.30529 0.292786ZM15.0123 19.9998C15.8079 19.9998 16.571 19.6837 17.1336 19.1211C17.6962 18.5585 18.0123 17.7954 18.0123 16.9998H16.0123C16.0123 17.265 15.9069 17.5194 15.7194 17.7069C15.5319 17.8944 15.2775 17.9998 15.0123 17.9998V19.9998ZM5.01229 17.9998C4.74707 17.9998 4.49272 17.8944 4.30518 17.7069C4.11765 17.5194 4.01229 17.265 4.01229 16.9998H2.01229C2.01229 17.7954 2.32836 18.5585 2.89097 19.1211C3.45358 19.6837 4.21664 19.9998 5.01229 19.9998V17.9998Z"
      fill="#FFA800"
    />
  </svg>
);

export default HomeIcon;
