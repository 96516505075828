import React from 'react';

const BuyIcon = () => (
  <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.8 15.3999H27.6C28.44 15.3999 29 14.8399 29 13.9999V9.7999C29 8.5399 28.02 7.4199 26.9 7.1399C24.38 6.4399 20.6 5.5999 20.6 5.5999C20.6 5.5999 18.78 3.6399 17.52 2.3799C16.82 1.8199 15.98 1.3999 15 1.3999H5.20003C4.36003 1.3999 3.66003 1.9599 3.24003 2.6599L1.28003 6.7199C1.09464 7.26061 1.00003 7.82828 1.00003 8.3999V13.9999C1.00003 14.8399 1.56003 15.3999 2.40003 15.3999H5.20003"
      stroke="#FFA800"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00001 18.2001C9.54641 18.2001 10.8 16.9465 10.8 15.4001C10.8 13.8537 9.54641 12.6001 8.00001 12.6001C6.45361 12.6001 5.20001 13.8537 5.20001 15.4001C5.20001 16.9465 6.45361 18.2001 8.00001 18.2001Z"
      stroke="#FFA800"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.8 15.3999H19.2" stroke="#FFA800" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M22 18.2001C23.5464 18.2001 24.8 16.9465 24.8 15.4001C24.8 13.8537 23.5464 12.6001 22 12.6001C20.4536 12.6001 19.2 13.8537 19.2 15.4001C19.2 16.9465 20.4536 18.2001 22 18.2001Z"
      stroke="#FFA800"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BuyIcon;
