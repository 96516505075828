import React, { useEffect } from 'react';
import {
  Container,
  Button,
  Box,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBrands, getAllModels } from '../server/api/vehicle';
import { useDevice } from '../hooks/useResponsive';
import { MetaHelmet } from '../layouts/Helmet/Helmet';
import { createLead } from '../server/api/lead';
import SubmitModal from './popup';

export default function SellPage() {
  const { isMobile } = useDevice();
  const dispatch = useDispatch();

  const { brands, models } = useSelector((state) => ({
    brands: state.vehicle.brands?.response,
    models: state.vehicle.models?.response,
  }));

  // const locationData = location?.state;

  // const [selectedCurtain, setSelectedCurtain] = useState(curtainTypesData[0]);
  // const handleCurtainTypeChange = (item) => {
  //   setSelectedCurtain(item);
  // };

  // useEffect(() => {
  //   if (locationData) {
  //     const currentItem = curtainTypesData?.filter((i) => i.category === locationData);
  //     setSelectedCurtain(currentItem[0]);
  //     window.scrollTo({
  //       top: 400,
  //       behavior: 'smooth',
  //     });
  //   } else {
  //     setSelectedCurtain(curtainTypesData[0]);
  //   }
  // }, [locationData]);
  const [brandId, setBrandId] = React.useState('');
  const [modelId, setModelId] = React.useState('');
  const [modal, setModal] = React.useState(false);

  const handleChange = (event, setItem) => {
    setItem(event.target.value);
  };

  const CustomButton = styled(Button)({
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    height: '50px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: '23px',
    fontWeight: '900',
    marginTop: '15px',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });
  const CustomButtonWeb = styled(Button)({
    width: '25%',
    background: '#F8971C',
    color: '#342C2C',
    borderRadius: '10px',
    height: '40px',
    boxShadow: '0 10px 10px rgba(0, 0, 0, 0.9)',
    fontSize: '20px',
    fontWeight: '900',
    marginTop: '15px',
    '&:hover': {
      background: '#F8971C',
      color: '#342C2C',
    },
  });

  useEffect(() => {
    const credentials = {
      dispatch,
      page: 1,
      limit: 10,
    };
    dispatch(getAllBrands(credentials));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      dispatch,
      page: 1,
      limit: 10,
      brand: brandId,
    };
    dispatch(getAllModels(credentials));
  }, [dispatch, brandId]);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      brand: '',
      model: '',
      makeYear: '',
      message: '',
      name: '',
      phone: '',
      pincode: '',
    },
  });

  const onSubmit = async (value, e) => {
    e?.preventDefault();
    const credentials = {
      state: {
        leadType: 'sell',
        requestedVehicle: {
          brand: brandId,
          model: modelId,
        },
        makeYear: value?.makeYear,
        message: value?.message,
        name: value?.name,
        phone: value?.phone,
        address: {
          pincode: parseInt(value?.pincode, 10),
        },
      },
      dispatch,
      reset,
      setModal,
    };
    dispatch(createLead(credentials));
    setModelId('');
    setBrandId('');
  };

  return (
    <>
      <MetaHelmet title={'Sell'} companyName={'Focus Motors'} />
      <SubmitModal open={modal} close={() => setModal(false)} />
      <Box className="bg-color">
        <Container maxWidth="lg" sx={{ padding: '0px 5px 0px 10px' }}>
          <Stack>
            <img
              src={
                isMobile
                  ? // eslint-disable-next-line react-hooks/rules-of-hooks
                    'assets/images/Sell/SellBanner.png'
                  : // eslint-disable-next-line react-hooks/rules-of-hooks
                    'assets/images/Sell/Sell.jpg'
              }
              alt=""
            />
          </Stack>
          <form onSubmit={handleSubmit((value, e) => onSubmit(value, e))}>
            <Box mt={3} px={1}>
              <Grid container>
                <Grid item md={isMobile ? '' : 4} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Brand</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={brandId}
                      label="Select Brand"
                      // placeholder="Select Brand"
                      className="custom-field-sell"
                      onChange={(e) => handleChange(e, setBrandId)}
                    >
                      <MenuItem value={''}>Select brand</MenuItem>
                      {brands?.brands?.map((item, key) => (
                        <MenuItem key={key} value={item?._id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid md={isMobile ? '' : 4} xs={12} px={isMobile ? '' : 1}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Model</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={modelId}
                      label="Select Brand"
                      // placeholder="Select Brand"
                      className="custom-field-sell"
                      onChange={(e) => handleChange(e, setModelId)}
                    >
                      <MenuItem value={''}>Select model</MenuItem>
                      {models?.models?.map((item, key) => (
                        <MenuItem key={key} value={item?._id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid md={isMobile ? '' : 4} xs={12}>
                  <TextField
                    fullWidth
                    name="makeYear"
                    placeholder="Enter Year"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('makeYear', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
                <Grid md={isMobile ? '' : 3} xs={12}>
                  <TextField
                    fullWidth
                    name="kiloMeter"
                    placeholder="Kilometers Driven"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('kiloMeter', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
                {isMobile && (
                  <Grid md={isMobile ? '' : 3} xs={12}>
                    <TextField
                      fullWidth
                      name="message"
                      placeholder="Type any other informations"
                      id="fullWidth"
                      type="text"
                      className="custom-field-sell"
                      rows={3}
                      multiline
                      {...register('message', {
                        required: { value: false, message: '' },
                      })}
                    />
                  </Grid>
                )}

                <Grid md={isMobile ? '' : 3} xs={12} px={isMobile ? '' : 1}>
                  <TextField
                    fullWidth
                    name="name"
                    placeholder="Enter your name"
                    id="fullWidth"
                    type="text"
                    className="custom-field-sell"
                    {...register('name', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
                <Grid md={isMobile ? '' : 3} xs={12} px={isMobile ? '' : 1}>
                  <TextField
                    fullWidth
                    name="phone"
                    placeholder="Enter your phone"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('phone', {
                      required: { value: false, message: '' },
                      maxLength: {
                        value: 15,
                        message: 'Phone must not exceed 15 characters',
                      },
                      minLength: {
                        value: 9,
                        message: 'Phone must have at least 9 characters',
                      },
                    })}
                  />
                </Grid>
                <Grid md={isMobile ? '' : 3} xs={12}>
                  <TextField
                    fullWidth
                    name="pincode"
                    placeholder="Enter your pincode"
                    id="fullWidth"
                    type="number"
                    className="custom-field-sell"
                    {...register('pincode', {
                      required: { value: false, message: '' },
                    })}
                  />
                </Grid>
                {!isMobile && (
                  <Grid md={isMobile ? '' : 12} xs={12}>
                    <TextField
                      fullWidth
                      name="message"
                      placeholder="Type any other informations"
                      id="fullWidth"
                      type="text"
                      className="custom-field-sell"
                      rows={3}
                      multiline
                      {...register('message', {
                        required: { value: false, message: '' },
                      })}
                    />
                  </Grid>
                )}
              </Grid>
              {isMobile ? (
                <CustomButton type="submit" variant="contained" className="request-btn" fullWidth>
                  Submit
                </CustomButton>
              ) : (
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                  <CustomButtonWeb type="submit" variant="contained" className="request-btn">
                    Submit
                  </CustomButtonWeb>
                </Box>
              )}
            </Box>
          </form>
        </Container>
      </Box>
    </>
  );
}
