import React from 'react';
import { Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Slider from 'react-slick'; // Import the Slider component from react-slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDevice } from '../../../hooks/useResponsive';
// eslint-disable-next-line import/no-unresolved

// Custom left arrow component

ReviewCard.propTypes = {
  addMargin: PropTypes.any,
};

export default function ReviewCard({ addMargin }) {
  const { isMobile } = useDevice();
  const CustomPrevArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-left LeftArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronLeftIcon /> {/* Use the ChevronLeftIcon from Material-UI Icons */}
      </div>
    );
  };

  // Custom right arrow component
  const CustomNextArrow = (props) => {
    // eslint-disable-next-line react/prop-types
    const { onClick } = props;
    return (
      <div
        className="arrow arrow-right RighrArrow"
        role="button"
        tabIndex="0"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick();
        }}
      >
        <ChevronRightIcon /> {/* Use the ChevronRightIcon from Material-UI Icons */}
      </div>
    );
  };

  // Configuration for the carousel
  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1.1 : 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />, // Custom left arrow component
    nextArrow: <CustomNextArrow />, // Custom right arrow component
  };

  const reviews = [
    {
      image: '/assets/images/Reviews/review2.jpg',
      // car: 'Innova Crista',
      // description: 'Kudos to FOCUS Motors for their transparent and efficient car buying process. Highly recommend!',
      // user: ' Shibili',
    },
    {
      image: '/assets/images/Reviews/review3.jpg',
      // car: 'Rolls Royce',
      // description: 'Kudos to FOCUS Motors for their transparent and efficient car buying process. Highly recommend!',
      // user: ' Rashid',
    },
    {
      image: '/assets/images/Reviews/review4.jpg',
      // car: 'Innova Crista',
      // description: 'Kudos to FOCUS Motors for their transparent and efficient car buying process. Highly recommend!',
      // user: ' Shifan',
    },
    {
      image: '/assets/images/Reviews/review5.jpg',
      // car: 'Fortuner',
      // description: 'Kudos to FOCUS Motors for their transparent and efficient car buying process. Highly recommend!',
      // user: ' Danish',
    },
    // {
    //   image: '/assets/images/Reviews/review1.png',
    //   car: 'Swift',
    //   description: 'Kudos to FOCUS Motors for their transparent and efficient car buying process. Highly recommend!',
    //   user: ' Joseph',
    // },
    // {
    //   image: '/assets/images/Reviews/review1.png',
    //   car: 'Swift',
    //   description: 'Kudos to FOCUS Motors for their transparent and efficient car buying process. Highly recommend!',
    //   user: ' Perera',
    // },
  ];
  return (
    <div style={{ marginBottom: addMargin ? '2rem' : 0 }}>
      <Slider {...carouselSettings}>
        {reviews?.map((review, key) => (
          <Card
            key={key}
            className="bg-color review-card"
            sx={{
              borderRadius: '0px',
              // boxShadow: '0px 10px 10px rgba(0, 0, 0, 1.0)',
              boxShadow: 'none',
              margin: '0px 10px 0px 10px',
            }}
          >
            <img
              src={review?.image}
              alt=""
              width={'100%'}
              style={{
                aspectRatio: 4 / 3,
                borderRadius: '10px',
              }}
            />
            {review?.car && (
              <Typography textAlign={'center'} color={'#FFA800'} pt={4}>
                {review?.car}
              </Typography>
            )}
            {review?.description && (
              <Typography color={'white'} fontSize={15} textAlign={'center'} px={3} pt={1}>
                {review?.description}
              </Typography>
            )}
            {review?.user && (
              <Typography color={'white'} fontSize={12} fontWeight={800} textAlign={'center'} py={3}>
                {review?.user}
              </Typography>
            )}
          </Card>
        ))}
      </Slider>
    </div>
  );
}
